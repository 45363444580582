import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SectionBox from "../components/section-box"
import SectionBox2 from "../components/section-box2"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"

//Ilustration inports
import ilustration2 from "../images/oblakoder-home-3.png"

//Styled Components Import

import SvgBg1 from "../components/styled/SvgBg"
import GridStyled1 from "../components/styled/Grid1"
import ImageBox1 from "../components/styled/ImageBox"

const AwsWellArchitected = () => (
  <Layout>
    <SEO title="AWS Well-Architected" />
    <SvgBg1>
      <GridStyled1>
        <div>
          <ScrollAnimation animateIn="fadeIn" duration={1.8}>
            <h1>
              <span>AWS</span> Well-Architected
            </h1>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp" delay={150} duration={1.3}>
            <h3>Nephele</h3>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeIn" delay={250} duration={1.4}>
            <SectionBox>
              <p>
                <b>
                  As general designs principles Well-Architected Framework
                  represents a set of principles to represents good design in
                  the cloud.
                </b>
              </p>
            </SectionBox>
            <SectionBox>
              <p>
                <b>
                  Eliminate guessing about your infrastructure capacity needs.
                </b>
              </p>
            </SectionBox>
            <SectionBox2>
              <p>
                In the cloud, you can create a production-scale test environment
                on demand, complete your testing, and then decommission the
                resources.
                <br />
                <br />
                <ul>
                  <li>Stop guessing your capacity needs</li>
                  <li>Test systems at production scale</li>
                  <li>Automate to make architectural experimentation easier</li>
                  <li>Allow for evolutionary architectures</li>
                  <li>Drive architectures using data</li>
                </ul>
                <br />
                If you are chief technology officers (CTOs), architect,
                developer or operations team members, we can help you start and
                to get involved with this principles and you will be in position
                to document your architectures.
              </p>
            </SectionBox2>
            <SectionBox>
              <p>
                <b>
                  With our help you will learn architectural best practices and
                  how it is aligned with cloud best practices.
                </b>
              </p>
            </SectionBox>
          </ScrollAnimation>
        </div>
        <ImageBox1>
          <div className="png-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
          <div className="res-img">
            <img src={ilustration2} alt="AWS Well-Architected" />
          </div>
        </ImageBox1>
      </GridStyled1>
    </SvgBg1>
  </Layout>
)

export default AwsWellArchitected
